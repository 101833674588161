class FilterCategories {
    constructor(selector) {
        this.selector = selector;

        this.elements = this.selector.querySelectorAll('[data-open]');
        if (this.elements.length > 0) {
            this.elements.forEach(element => {
                let title = element.querySelector('[data-element="title"]');
                if (title !== null && element.classList.contains('has-submenu')) {
                    title.addEventListener('click', this._toggleElement)
                }
            })
        }

    }
 
    _toggleElement(event) {
        event.preventDefault();
        let parent = event.target.closest('[data-open]');
        if (parent.classList.contains('open')) {
            parent.classList.remove('open');
            parent.setAttribute('data-open', 0);
        } else {
            parent.classList.add('open');
            parent.setAttribute('data-open', 1);
        }
    }

}

export default FilterCategories;