class Cart {
    constructor() {
        this.url = '/wp-admin/admin-ajax.php';
        this.cart_url = (window.nt_cart_url) ? window.nt_cart_url : '';

        this.addToCartForm = this.addToCartForm.bind(this);
        this.addToCartButton = this.addToCartButton.bind(this);
        this._closeGlobalModal = this._closeGlobalModal.bind(this);
        this._openSideCart = this._openSideCart.bind(this);
        this._closeSideCart = this._closeSideCart.bind(this);
        this._removeFromCart = this._removeFromCart.bind(this);
        this._maybeCloseModalOrCart = this._maybeCloseModalOrCart.bind(this);

        this.global_modal = document.querySelector('.js-cart-modal');
        this.global_modal_content = null;
        this.global_modal_close = null;

        this.cart_toggle = document.querySelector('.js-cart-toggle');
        this.side_cart = document.querySelector('.js-side-cart');

        if (this.global_modal !== null) {
            this.global_modal_content = this.global_modal.querySelector('[data-element="content"]');
            this.global_modal_close = this.global_modal.querySelector('[data-element="close"]');
            this.modal_is_open = false;

            if (this.global_modal_close !== null) {
                this._closeGlobalModal = this._closeGlobalModal.bind(this);
                this.global_modal_close.addEventListener('click', this._closeGlobalModal);
            }
        }

        if (this.cart_toggle !== null) {
            this.cart_count = this.cart_toggle.querySelector('[data-element="count"]');
            if (this.side_cart !== null) {
                this.cart_toggle.addEventListener('click', this._openSideCart);
            }
        }
        if (this.side_cart !== null) {
            this.side_cart_is_open = false;
            this.side_cart_close = this.side_cart.querySelector('.js-cart-close');
            this.side_cart_content = this.side_cart.querySelector('[data-element="cart-content"]');
            if (this.side_cart_close !== null) {
                this.side_cart_close.addEventListener('click', this._closeSideCart);
            }
        }

        document.addEventListener('DOMContentLoaded', e => {
            this._reloadCart();
        })

        document.addEventListener('click', this._maybeCloseModalOrCart);

    }

    _addCartItem(product_id, quantity = 1) {
        const body_data = new FormData();
        body_data.append('action', 'netivo_add_to_cart');
        body_data.append('product_id', product_id);
        body_data.append('quantity', quantity);

        const body_params = new URLSearchParams(body_data);
        fetch(this.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: body_params
        }).then((response) => response.json())
            .then((response) => {
                if (response.status === 'success') {
                    //this._updateBag('cart-add', product_id,null, response.data);
                    this._display_modal(response.message, response.status, response.data, quantity);
                    this._maybe_update_count(response.data.total.quantity);
                    this._updateSideCart(response.data.cart);
                } else {
                    this._display_modal(response.message, response.status, null, null);
                }
            }).catch((error) => {
            console.error('Error:', error);
            this._display_modal(error.message, error.status, null, null);
        });
    }

    _display_modal(message, type = 'success', data, product_qty = 1) {

        let msg = document.createElement('div');
        msg.classList.add('modal__message');
        if (type === 'success') {
            let span = document.createElement('span');
            span.classList.add('title', 'title--small', 'text-center');
            span.innerHTML = message;

            let modal_product = document.createElement('div');
            modal_product.classList.add('modal__product');

            let modal_product_image = document.createElement('img');
            modal_product_image.classList.add('modal__product-image');
            modal_product_image.setAttribute('alt', data.product.product.name);
            modal_product_image.setAttribute('src', data.product.product.image);

            modal_product.appendChild(modal_product_image);

            let modal_product_data = document.createElement('div');
            modal_product_data.classList.add('modal__product-data');

            let modal_product_name = document.createElement('div');
            modal_product_name.classList.add('modal__product-name');
            modal_product_name.innerHTML = data.product.product.name;

            let modal_product_attr = document.createElement('div');
            modal_product_attr.classList.add('modal__product-attr');

            let modal_product_price = document.createElement('div');
            modal_product_price.classList.add('modal__product-price');
            modal_product_price.innerHTML = 'Cena: ';

            let price_amount = document.createElement('span');
            price_amount.classList.add('woocommerce-Price-amount', 'amount');
            price_amount.innerHTML = (parseFloat(parseFloat(data.product.product.cprice) * parseFloat(product_qty))).toFixed(2);

            let price_currency = document.createElement('span');
            price_currency.classList.add('woocommerce-Price-currencySymbol');
            price_currency.innerHTML = '&#122;&#322;';

            price_amount.appendChild(price_currency);
            modal_product_price.appendChild(price_amount);

            let modal_product_amount = document.createElement('div');
            modal_product_amount.classList.add('modal__product-amount');
            modal_product_amount.innerHTML = 'Ilość: ' + product_qty + 'szt.';

            modal_product_attr.appendChild(modal_product_price);
            modal_product_attr.appendChild(modal_product_amount);

            modal_product_data.appendChild(modal_product_name);
            modal_product_data.appendChild(modal_product_attr);

            modal_product.appendChild(modal_product_data);

            let modal_buttons = document.createElement('div');
            modal_buttons.classList.add('modal__buttons');

            let button_continue = document.createElement('a');
            button_continue.classList.add('btn', 'btn--continue-shop');
            button_continue.innerText = 'Wróć do zakupów';
            button_continue.setAttribute('title', 'Wróć do zakupów');
            button_continue.setAttribute('data-element', 'close');

            button_continue.addEventListener('click', e => {
                e.preventDefault();

                if (this.global_modal.classList.contains('modal--shown')) {
                    this.global_modal.classList.remove('modal--shown');
                }
            });

            modal_buttons.appendChild(button_continue);

            if (this.cart_url !== '') {
                let button_cart = document.createElement('a');
                button_cart.classList.add('btn', 'btn--cart');
                button_cart.innerText = 'Pokaż koszyk';
                button_cart.setAttribute('title', 'Przejdź do koszyka');
                button_cart.setAttribute('href', this.cart_url);
                modal_buttons.appendChild(button_cart);
            }
            msg.appendChild(span);
            msg.appendChild(modal_product);
            msg.appendChild(modal_buttons);
        } else if (type === 'error') {
            msg.classList.add('modal__message--error');

            let span = document.createElement('span');
            span.classList.add('title', 'title--size-20', 'text-center');
            span.innerHTML = message;
            msg.appendChild(span);
        }

        this.global_modal_content.innerHTML = '';
        this.global_modal_content.appendChild(msg);
        this.global_modal.classList.add('modal--shown');
        this.modal_is_open = true;
    }

    _maybe_update_count(new_count) {
        if (this.cart_count !== null) {

            if (new_count > 0) this.cart_count.style.display = 'flex';
            else this.cart_count.style.display = 'none';
            this.cart_count.innerHTML = new_count;
        }
    }

    _closeGlobalModal(e) {
        e.preventDefault();

        if (this.global_modal.classList.contains('modal--shown')) {
            this.global_modal.classList.remove('modal--shown');
        }
    }

    _openSideCart(event) {
        event.preventDefault();
        if (this.side_cart.classList.contains('toggled')) {
            this.side_cart_is_open = false
            this.side_cart.classList.remove('toggled');
        } else {
            this.side_cart_is_open = true;
            this.side_cart.classList.add('toggled');
        }
    }

    _closeSideCart(event) {
        event.preventDefault();
        this.side_cart.classList.remove('toggled');
        this.side_cart_is_open = false;
    }

    _updateSideCart(cart_html) {
        if (this.side_cart_content !== null) {
            this.side_cart_content.innerHTML = cart_html;

            let cart_remove = this.side_cart_content.querySelectorAll('[data-element="cart-remove"]');
            if (cart_remove.length > 0) {
                cart_remove.forEach(cr => {
                    cr.addEventListener('click', this._removeFromCart);
                })
            }
        }
    }

    _reloadCart() {
        const reload_data = new FormData();
        reload_data.append('action', 'netivo_get_cart_data');

        const reload_params = new URLSearchParams(reload_data);

        fetch(this.url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: reload_params
        }).then((response) => response.json())
            .then((response) => {
                this._updateSideCart(response.cart);
                this._maybe_update_count(response.quantity);
            }).catch((error) => {
            console.log(error);
        });
    }

    _removeFromCart(event) {
        event.preventDefault();
        let product_id = event.target.getAttribute('data-product-id');
        let cart_key = event.target.getAttribute('data-cart-item-key');

        const reload_data = new FormData();
        reload_data.append('action', 'netivo_remove_from_cart');
        reload_data.append('cart_key', cart_key);
        reload_data.append('product_id', product_id);

        const reload_params = new URLSearchParams(reload_data);

        fetch(this.url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: reload_params
        }).then((response) => response.json())
            .then((response) => {
                if (response.status === 'success') {
                    this._updateSideCart(response.data.cart);
                    this._maybe_update_count(response.data.quantity);
                } else {
                    this._display_modal(response.message, response.status, null, null);
                }
            }).catch((error) => {
            console.log(error);
        });
    }

    _maybeCloseModalOrCart(event) {
        if (this.global_modal !== null) {
            if (this.modal_is_open) {
                let modal = event.target.closest('.js-cart-modal');
                if (modal === null) {
                    this.global_modal.classList.remove('modal--shown');
                    this.modal_is_open = false;
                }
            }
        }
        if (this.side_cart !== null) {
            if (this.side_cart_is_open) {
                let cart = event.target.closest('.js-side-cart');
                let cart_toggle = event.target.closest('.js-cart-toggle');
                if (cart === null && cart_toggle === null) {
                    this.side_cart.classList.remove('toggled');
                    this.side_cart_is_open = false;
                }
            }
        }
    }

    addToCartButton(event) {
        event.preventDefault();

        let product_id = event.target.getAttribute('data-product-id');
        //console.log(product_id);
        if (product_id !== null && product_id !== '') {
            this._addCartItem(product_id);
        }
    }

    addToCartForm(event) {
        event.preventDefault();
        let qty = event.target.quantity.value;
        let product_id = event.target.getAttribute('data-product-id');
        if (qty !== undefined && product_id !== '') {
            qty = parseInt(qty);
            this._addCartItem(product_id, qty);
        }
    }
}

export default Cart;