import $ from 'jquery';

$(document).ready(function () {
    $('input#min_price, input#max_price').hide();
    $('.price_slider, .price_label').show();

    var min_price = parseInt($('.price_slider_amount #min_price').data('min')),
        max_price = parseInt($('.price_slider_amount #max_price').data('max')),
        current_min_price = parseInt($('.price_slider_amount #min_price').val()),
        current_max_price = parseInt($('.price_slider_amount #max_price').val()),
        global_min_price = parseInt($('.price_slider_amount #min_price').val()),
        global_max_price = parseInt($('.price_slider_amount #max_price').val());

    console.log('g  --  ' + global_min_price, global_max_price);

    var $price_slider = $('.price_slider:not(.ui-slider)');
    if ($price_slider.length > 0) {
        $price_slider.slider({
            range: true,
            animate: true,
            min: min_price,
            max: max_price,
            values: [current_min_price, current_max_price],
            step: 1,
            create: function () {

                $('.price_slider_amount #min_price').val(current_min_price);
                $('.price_slider_amount #max_price').val(current_max_price);

                $('.price_slider_amount span.from').html(current_min_price);
                $('.price_slider_amount span.to').html(current_max_price);

                $(document.body).trigger('price_slider_create', [current_min_price, current_max_price]);
                $(document.body).trigger('price_slider_slide', [current_min_price, current_max_price]);
            },
            slide: function (event, ui) {

                $('input#min_price').val(ui.values[0]);
                $('input#max_price').val(ui.values[1]);

                $('input#min_price').trigger('change');
                $('input#max_price').trigger('change');

                $('.price_slider_amount span.from').html(ui.values[0]);
                $('.price_slider_amount span.to').html(ui.values[1]);

                $(document.body).trigger('price_slider_slide', [ui.values[0], ui.values[1]]);
            },
            change: function (event, ui) {

                $('.price_slider_amount span.from').html(ui.values[0]);
                $('.price_slider_amount span.to').html(ui.values[1]);

                $(document.body).trigger('price_slider_updated', [ui.values[0], ui.values[1]]);
            }
        });

        $('input#min_price').on('change', function (e) {
            current_max_price = parseInt($('.price_slider_amount #max_price').val());
            var current_min = parseInt($(this).val());
            var $real_max_price = $('#real_max_price');
            var $real_min_price = $('#real_min_price');

            if (current_min >= min_price) {
                if (current_min > max_price) {
                    $price_slider.slider({
                        values: [max_price, max_price]
                    });
                    $(this).val(max_price);
                    $('.price_slider_amount #max_price').val(max_price);
                    $real_max_price.val(max_price);
                    $real_min_price.val(max_price);
                    $real_max_price.attr('disabled', '');
                    $real_min_price.removeAttr('disabled');
                } else if (current_min > current_max_price) {
                    $price_slider.slider({
                        values: [current_min, current_min]
                    });
                    $('.price_slider_amount #max_price').val(current_min);
                    $real_max_price.val(current_min);
                    $real_max_price.removeAttr('disabled');
                } else {
                    $price_slider.slider({
                        values: [current_min, current_max_price]
                    });
                    $real_min_price.val(current_min);
                    $real_min_price.removeAttr('disabled');
                }
            } else {
                $price_slider.slider({
                    values: [min_price, current_max_price]
                });
                $(this).val(min_price);

                $real_min_price.val(min_price);
                $real_min_price.attr('disabled', '');
            }
        });

        $('input#max_price').on('change', function (e) {
            current_min_price = parseInt($('.price_slider_amount #min_price').val());
            var current_max = parseInt($(this).val());
            var $real_max_price = $('#real_max_price');
            var $real_min_price = $('#real_min_price');

            if (current_max <= max_price) {
                if (current_max < min_price) {
                    $price_slider.slider({
                        values: [min_price, min_price]
                    });
                    $(this).val(min_price);
                    $('.price_slider_amount #min_price').val(min_price);
                    $real_max_price.val(min_price);
                    $real_min_price.val(min_price);
                    $real_max_price.removeAttr('disabled');
                    $real_min_price.attr('disabled', '');
                } else if (current_max < current_min_price) {
                    $price_slider.slider({
                        values: [current_max, current_max]
                    });
                    $('.price_slider_amount #min_price').val(current_max);
                    $real_min_price.val(current_max);
                    $real_min_price.removeAttr('disabled');
                } else {
                    $price_slider.slider({
                        values: [current_min_price, current_max]
                    });
                    $real_max_price.val(current_max);
                    $real_max_price.removeAttr('disabled');
                }
            } else {
                $price_slider.slider({
                    values: [current_min_price, max_price]
                });
                $(this).val(max_price);

                $real_max_price.val(max_price);
                $real_max_price.attr('disabled', '');
            }
        });
    }
});