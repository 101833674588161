import FilterCategories from "./../filterCategories";
import QuantityInput from "../quantityInput";
import Accordion from "../accordion";
import Cart from "../cart";
import HiddenFilters from "../hiddenFilters";
import AttributeFilter from "../attributeFilter";
import * as rangeSlider from "./../rangeSlider";
import Filters from "../filters";
import Tabbed from "../tabbed";
import PaginationInput from "../paginationInput";

let filterCategories = document.querySelector('.js-filter-categories');
if (filterCategories !== null) {
    new FilterCategories(filterCategories);
}
let data_filters_hidden = document.querySelectorAll('.js-hidden-filter-options');
if (data_filters_hidden.length > 0) {
    data_filters_hidden.forEach(filter => {
        new HiddenFilters(filter);
    });
}
let attribute_filter = document.querySelectorAll('.js-attribute-filter');
if (attribute_filter.length > 0) {
    attribute_filter.forEach(filter => {
        new AttributeFilter(filter);
    });
}

let quantityInput = document.querySelectorAll('.js-quantity-input');
if (quantityInput.length > 0) {
    quantityInput.forEach(inp => {
        new QuantityInput(inp);
    })
}

let paginationInputs = document.querySelectorAll('.js-paginate-input');
if (paginationInputs.length > 0) {
    paginationInputs.forEach(inp => {
        new PaginationInput(inp);
    })
}

let data_filters = document.querySelectorAll('.js-single-filter');
if (data_filters !== null) {
    data_filters.forEach(filter => {
        new Filters(filter);
    });
}

document.addEventListener('nt_updated_cart_totals', e => {
    let quantityInput = document.querySelectorAll('.js-quantity-input');
    if (quantityInput.length > 0) {
        quantityInput.forEach(inp => {
            new QuantityInput(inp);
        })
    }
})

let accordion = document.querySelectorAll('.js-accordion');
if (accordion.length > 0) {
    accordion.forEach(acc => {
        new Accordion(acc);
    })
}

let cart = new Cart();

let addToCartButton = document.querySelectorAll('.js-add-to-cart');
if (addToCartButton.length > 0) {
    addToCartButton.forEach(acb => {
        acb.addEventListener('click', cart.addToCartButton);
    })
}

let addToCartForm = document.querySelectorAll('.js-add-to-cart-form');
if (addToCartForm.length > 0) {
    addToCartForm.forEach(acf => {
        acf.addEventListener('submit', cart.addToCartForm);
    })
}

let accordionFaq = document.querySelectorAll('.js-faq-accordion');
if (accordionFaq.length > 0) {
    accordionFaq.forEach(acc => {
        new Accordion(acc);
    })
}

let tabbedSection = document.querySelectorAll('.js-tabbed');
if (tabbedSection.length > 0) {
    tabbedSection.forEach(ts => {
        new Tabbed(ts);
    })
}

window.initMap = function () {
    var maps = document.getElementsByClassName('js-map');
    for (var j = 0; j < maps.length; j++) {
        var el = maps[j];
        var lat = el.dataset.lat;
        var lng = el.dataset.lng;
        var zoom = (isNaN(parseInt(el.dataset.zoom))) ? 14 : el.dataset.zoom;
        var title = el.dataset.title;
        var content = el.dataset.content;
        var center = el.dataset.center;

        if (lat !== '' && typeof lat !== 'undefined' && lng !== '' && typeof lng !== 'undefined') {
            lat = lat.split(';');
            lng = lng.split(';');

            if (lat.length === lng.length) {
                var centerlat = 0;
                var centerlng = 0;
                for (i = 0; i < lat.length; i++) {
                    centerlat += parseFloat(lat[i]);
                    centerlng += parseFloat(lng[i]);
                }
                centerlng = centerlng / lng.length;
                centerlat = centerlat / lat.length;
                if (typeof center !== 'undefined') {
                    centerlng -= 0.03;
                }

                var myOptions = {
                    center: new google.maps.LatLng(centerlat, centerlng),
                    zoom: parseInt(zoom),
                    scrollwheel: false,
                    scaleControl: false,
                    disableDefaultUI: false,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles: [
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dadada"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#c9c9c9"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        }
                    ]
                };
                var map = new google.maps.Map(el, myOptions);
                if ((content !== '' && typeof content !== 'undefined') || (title !== '' && typeof title !== 'undefined')) {
                    content = content.split(';');
                    title = title.split(';');
                    if (title.length === lat.length) {
                        var marker = [];
                        for (var i = 0; i < lat.length; i++) {
                            var mkOpitons = {
                                icon: 'https://mt.googleapis.com/vt/icon/name=icons/spotlight/spotlight-poi.png',
                                position: new google.maps.LatLng(parseFloat(lat[i]), parseFloat(lng[i])),
                                map: map
                            };
                            if (title[i] !== '' && typeof title[i] !== 'undefined') {
                                mkOpitons.title = title[i];
                            }
                            marker[i] = new google.maps.Marker(mkOpitons);
                        }
                        var infowindow = [];
                        for (i = 0; i < lat.length; i++) {
                            if (content[i] !== '' && typeof content[i] !== 'undefined') {
                                infowindow[i] = new google.maps.InfoWindow({
                                    content: content[i]
                                });
                            }
                        }
                        // console.log(infowindow);
                        for (i = 0; i < lat.length; i++) {
                            if (content[i] !== '' && typeof content[i] !== 'undefined') {
                                (function (m, iw, mk, id) {
                                    google.maps.event.addListener(mk[id], 'click', function () {
                                        iw[id].open(m, mk[id]);
                                    });
                                })(map, infowindow, marker, i);
                            }
                        }
                    } else {
                        console.log('Niezgodna ilość punktów do naniesienia na mapę.');
                        el.innerHTML = "<p style='text-align: center; line-height:500px ; font-size:40px; margin: 0;' >Przepraszamy, nie możemy wyświetlić mapy</p>";
                    }
                }
            } else {
                console.log('Niezgodna ilość punktów do naniesienia na mapę.');
                el.innerHTML = "<p style='text-align: center; line-height:500px ; font-size:40px; margin: 0;' >Przepraszamy, nie możemy wyświetlić mapy</p>";
            }
        } else {
            console.log('Podaj poprawne atrybuty data - lat oraz lng');
            el.innerHTML = "<p style='text-align: center; line-height:500px ; font-size:40px; margin: 0;' >Przepraszamy, nie możemy wyświetlić mapy</p>";
        }
    }
}