class Filters {
    constructor(selector) {
        this.selector = selector;
        this.optionList = this.selector.querySelector('[data-option-list]');
        this.hiddenSelect = this.selector.querySelector('[data-hidden-select]');

        this.inputHidden = null;
        this.search = null;

        this.clicker = this.selector.querySelector('[data-element="clicker"]');

        let dataType = this.selector.getAttribute('data-type');
        let input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', dataType);
        input.setAttribute('value', '');
        input.setAttribute('data-input-hidden', '');
        input.setAttribute('data-result', '');

        this.selector.appendChild(input);
        this.results = this.selector.querySelector('[data-result]');

        this.inputHidden = this.selector.querySelector('[data-input-hidden]');

        this.search = document.createElement('input');
        this.search.setAttribute('type', 'text');
        this.search.setAttribute('placeholder', 'Szukaj');
        this.search.classList.add('filters__search');

        if (this.selector.querySelector('[data-option]').length > 8) {
            this.optionList.wrap('<div class="js-filter-scroll"></div>'); // TODO: jQuery
            this.hiddenSelect.prepend(this.search);
        }

        this._optionClick = this._optionClick.bind(this);
        let options = this.optionList.querySelectorAll('[data-option]');
        if (options.length > 0) {
            options.forEach(opt => {
                opt.addEventListener('click', this._optionClick);
            });
        }

        this._showFilters = this._showFilters.bind(this);

        this._searchFilter = this._searchFilter.bind(this);
        this.search.addEventListener('keyup', this._searchFilter);

        // document.body.addEventListener('click', this._hideWhenOutside);

        this._updateResults();

        // let value = this.inputHidden.getAttribute('value');
        // if (value.length !== 0) {
        //     let x = this.selector.children[0];
        //     x.style.border = '1px solid #512178';
        // }

        if (this.clicker !== null) {
            this.clicker.addEventListener('click', (e) => {
                e.preventDefault();

                if (this.selector.classList.contains('open')) {
                    this.selector.classList.remove('open');
                } else {
                    this.selector.classList.add('open');
                }
            });
        }
        document.addEventListener('click', e => {
            let isClickInside = this.selector.contains(e.target);
            if (!isClickInside) {
                if (this.selector.classList.contains('open')) {
                    this.selector.classList.remove('open');
                }
            }
        });
    }

    _optionClick(e) {
        e.preventDefault();

        if (e.target.classList.contains('active')) {
            e.target.classList.remove('active');
        } else {
            e.target.classList.add('active');
        }
        this._updateResults();
    }

    _showFilters(e) {
        e.preventDefault();

        if (this.hiddenSelect.classList.contains('active')) {
            this.hiddenSelect.classList.remove('active');

            this.hiddenSelect.closest('[data-filter]').querySelector('.table__filters--data').classList.remove('active');
        } else {
            this.hiddenSelect.classList.add('active');

            this.hiddenSelect.closest('[data-filter]').querySelector('.table__filters--data').classList.add('active');
        }
        this._updateResults();
    }

    _searchFilter(e) {
        let items = this.selector.querySelectorAll('li');
        let filter = this.search.value.toUpperCase();
        //console.log(items, filter);
        for (let i = 0; i < items.length; i++) {
            let txtValue = items[i].textContent || items[i].innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                items[i].style.display = "";
            } else {
                items[i].style.display = "none";
            }
        }
    }

    _hideWhenOutside() {

    }

    _updateResults() {
        let values = this.optionList.querySelectorAll('[data-option].active');
        let tmp_val = [];

        if (values !== null) {
            values.forEach(item => {
                tmp_val.push(item.getAttribute('data-value'));
            });
        }
        this.inputHidden.value = tmp_val.join(',');
    }
}

export default Filters;