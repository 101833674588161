class Accordion {
    constructor(selector) {
        this.selector = selector;
        this.open = this.selector.getAttribute('data-open');
        this.toggle = this.selector.querySelector('[data-element="toggle"]');

        this.toggleContent = this.toggleContent.bind(this);

        if (this.toggle !== null) {
            this.toggle.addEventListener('click', this.toggleContent);
        }
    }

    toggleContent(event) {
        event.preventDefault();
        if (this.open == '1') {
            this.selector.setAttribute('data-open', '0');
            this.open = '0';
        } else {
            this.selector.setAttribute('data-open', '1');
            this.open = '1';
        }
    }
}

export default Accordion