// constructor(selector) {
//     this.selector = selector;
//     this.tabLabels = this.selector.querySelectorAll('.tabs__nav .tabs__nav-item');
//     this.tabPanes = this.selector.querySelectorAll('.tabs__content .tabs__content-item');
//
//     this.activateTab = this.activateTab.bind(this);
//
//     if (this.tabLabels !== null) {
//         this.tabLabels.addEventListener('click', this.activateTab);
//     }
//
//
// }
//
// activateTab(e) {
//     e.preventDefault();
//
//     // Deactivate all tabs
//     this.tabLabels.forEach(function (label, index) {
//         label.classList.remove("active");
//     });
//     [].forEach.call(this.tabPanes, function (pane, index) {
//         pane.classList.remove("active");
//     });
//
//     // Activate current tab
//     e.target.parentNode.classList.add("active");
//     let clickedTab = e.target.getAttribute("href");
//     document.querySelector(clickedTab).classList.add("active");
// }
class Tabbed {
    constructor(element) {
        // Przechowuje element główny zawierający zakładki
        this.element = element;

        // Pobierz elementy zakładek nawigacyjnych (przyciski/odnośniki)
        this.tabsNavItems = this.element.querySelectorAll('.tabs__nav-item a');

        // Pobierz wszystkie elementy z treścią zakładek
        this.tabsContentItems = this.element.querySelectorAll('.tabs__content-item');

        // Inicjalizacja funkcji obsługi zakładek
        this.init();
    }

    // Funkcja inicjalizująca - dodaje nasłuchiwacze zdarzeń
    init() {
        // Dodaj listener na każdą zakładkę
        this.tabsNavItems.forEach((navItem) => {
            navItem.addEventListener('click', (event) => {
                event.preventDefault();  // Zapobiegaj domyślnemu zachowaniu (np. przewijanie do #id)
                const targetTab = navItem.getAttribute('href');  // Pobierz ID zakładki, na którą kliknięto

                // Ukryj wszystkie treści i usuń klasy aktywne
                this.hideAllTabsContent();
                this.removeActiveClassFromTabs();

                // Pokaż odpowiednią zawartość i ustaw aktywną zakładkę
                this.showTabContent(targetTab);
                navItem.parentElement.classList.add('active');  // Dodaj klasę active do zakładki
            });
        });

        // Pokazanie pierwszej zakładki po załadowaniu strony
        if (this.tabsNavItems.length > 0) {
            this.tabsNavItems[0].click();
        }
    }

    // Funkcja ukrywania wszystkich treści zakładek
    hideAllTabsContent() {
        this.tabsContentItems.forEach((contentItem) => {
            contentItem.classList.remove('active');
        });
    }

    // Funkcja usuwania klasy aktywnej ze wszystkich zakładek
    removeActiveClassFromTabs() {
        this.tabsNavItems.forEach((navItem) => {
            navItem.parentElement.classList.remove('active');
        });
    }

    // Funkcja pokazywania wybranej zakładki
    showTabContent(id) {
        const tabContent = this.element.querySelector(id);
        if (tabContent) {
            tabContent.classList.add('active');
        }
    }
}

export default Tabbed;